<template>
  <div>
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

    <div id="form-view" :style="{ width: formWidth }">
      <!-- form meta -->

      <div class="header">
        <div class="col">
          <div class="title-1">{{ formName }}</div>
          <div class="description">{{ formDescription }}</div>
        </div>

        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          @click="toggleWidth"
        />
        <ThemeSwitcher class="q-ml-sm" :no-border="false" />
      </div>

      <!-- ... -->

      <!-- form -->
      <RenderForm
        v-if="form"
        :form-id="formId"
        :panels="panels"
        :secondary-panels="secondaryPanels"
        :form-settings="formSettings"
        :form-model="formModel"
        :enable-controls="_enableControls"
        :height="'calc(100vh - 200px)'"
        :external-link="true"
        :portal-id="portal.id"
        :external-loading.sync="externalLoading"
        :form-upload-files="formUploadFiles"
        :readonly-controls="readonlyControls"
        :workflow-model.sync="workflowModel"
        @save="saveForm"
        @formSaveType="formSaveType"
        @uploadFiles="getOcrResult"
        @deletedFiles="deletedFiles"
      />
      <!-- @showQr="generateQrCode" -->

      <!-- ... -->

      <!-- QR Print -->

      <QRView
        v-model="isQRView"
        :form-id="formId"
        :form-entry-id="formEntryId"
        @input="closeQRModal"
      />

      <GenerateQr
        :value="showQr"
        :repository-id="repositoryId"
        :field-id="selectedFieldId"
        :field-name="selectedFieldName"
        :is-file-uploaded="QRFileUploaded"
        :settings="fileControlFieldSettings"
        @checkUploadId="checkMobileUpload"
        @expired="QRexpired"
        @input="closeQR"
      />

      <!-- ... -->

      <!-- footer -->

      <div class="footer">
        <div class="label">Powered by</div>
        <img src="@/assets/logo/logo-light.png" alt="logo" />
      </div>

      <!-- ... -->

      <!-- modal -->

      <Modal
        v-model="submissionModal"
        width="30vw"
        height="50vh"
        has-footer
        @input="closeSubmission()"
      >
        <!-- title -->

        <template #title>Thank you!</template>

        <!-- ... -->

        <template #default>
          <BaseIcon
            name="eva-checkmark-circle-2-outline"
            color="positive"
            size="80px"
            class="imgCenter"
          />
          <div class="text-center">
            Thank you for submitting the form. We appreciate your participation
            and the information you have provided.
          </div>
        </template>

        <!-- footer -->

        <template #footer>
          <BaseButton label="Close" @click="closeSubmission" />
        </template>
      </Modal>

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import { auth, form, portal, repository } from "@/api/factory.js";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import QRView from "@/components/common/QRView.vue";
import Modal from "@/components/common/popup/Modal.vue";
import GenerateQr from "@/components/common/form/file-upload-field/GenerateQr.vue";
import { decrypt } from "@/helpers/crypto";
import { isEmpty } from "lodash-es";
import axios from "axios";

export default {
  name: "FormView",

  components: { ThemeSwitcher, RenderForm, QRView, Modal, GenerateQr },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    formId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingBarContent: true,
      form: null,
      formWidth: "720px",
      formModel: {},
      isQRView: false,
      formEntryId: 0,
      submissionModal: false,
      sendInviteBtn: false,
      portal: {},
      savingType: "",
      formItemId: 0,
      externalLoading: false,
      formUploadFiles: [],
      fileControlDeleteFile: [],
      readonlyControls: [],
      workflowModel: {},
      ocrResult: [],
      repositoryId: 0,
      showQr: false,
      selectedFieldId: "",
      selectedFieldName: "",
      QRFileUploaded: false,
      uploadInterval: null,
      fileControlFieldSettings: {},
      mobileUploadFileJson: "",
      repositoryDetails: [],
      repositoryField: [],
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100%";
    },

    formName() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.name;
    },

    formDescription() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.description;
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }

      return this.form.settings;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (
                          controls.indexOf(control) < 0 &&
                          listControls.indexOf(control) < 0
                        ) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    formFields() {
      const fields = [];

      if (!this.form) {
        return fields;
      }

      for (let panel of this.form.panels) {
        for (let field of panel.fields) {
          fields.push({
            id: field.id,
            type: field.type,
            label: field.label,
          });
        }
      }

      return fields;
    },
  },

  watch: {
    tenantId: {
      immediate: true,
      handler: "getSession",
    },

    externalLoading: {
      immediate: true,
      handler() {
        if (this.externalLoading) {
          this.loadingBarContent = true;
        } else {
          this.loadingBarContent = false;
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100%" ? "720px" : "100%";
    },

    async getSession() {
      this.loadingBarContent = true;
      const { error } = await auth.externalLogin(this.tenantId, 1);
      if (error) {
        this.loadingBarContent = false;
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getForm();
      this.getPortals();
    },

    async getForm() {
      const { error, payload } = await form.getForm(this.formId);
      this.loadingBarContent = false;

      if (error) {
        this.loadingBarContent = false;
        this.$alert.error(error);
        return;
      }

      this.form = JSON.parse(payload.formJson);
      this.repositoryId = payload.repositoryId;
      if (this.repositoryId) {
        this.getRepository();
      }
    },

    async getPortals() {
      this.portal = {};
      this.sendInviteBtn = false;
      const { error, payload } = await portal.getPortalList({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: [
          {
            filters: [
              {
                criteria: "settingsJson",
                condition: "CONTAINS",
                value:
                  '"loginType":"MASTER_LOGIN","formId":' + this.formId + ",",
              },
            ],
            groupCondition: "",
          },
        ],
        hasSecurity: false,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          if (data[0].value.length) {
            this.portal = data[0].value[0];
            if (this.portal.settingsJson) {
              let settings = JSON.parse(this.portal.settingsJson);
              if (
                settings.authentication.loginType === "MASTER_LOGIN" &&
                settings.authentication.notification
              ) {
                this.sendInviteBtn = true;
              }
            }
          }
        }
      }
    },

    async saveForm(fieldsModel) {
      this.loadingBarContent = true;

      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && fieldsModel[field.id]) {
          let ids = [];

          fieldsModel[field.id].forEach((item) => {
            if (item.fileId) ids.push(item.fileId);
          });

          if (ids.length) {
            formUpload.push({
              jsonId: field.id,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete fieldsModel[field.id];
        }
      });

      let input = {
        fields: fieldsModel,
        formUpload: formUpload,
      };
      let entryId = 0;
      if (this.savingType === "ADD") {
        entryId = 0;
      } else if (this.savingType === "UPDATE") {
        entryId = this.formItemId;
      }
      const { data, error } = await form.saveFormEntry(
        this.formId,
        entryId,
        input
      );

      this.loadingBarContent = false;

      if (error) {
        if (error.data) {
          let errorData = JSON.parse(error.data);
          console.log(errorData);
          let fields = JSON.parse(errorData.output);
          let fieldNames = [];
          fields.forEach((row) => {
            fieldNames.push(row.name);
          });
          this.$alert.warning("Already Exists!", fieldNames);
        }
        this.$alert.error(error);
        return;
      }

      if (this.sendInviteBtn) {
        this.sendInvite(data.output);
      }

      if (this.form.settings.general.qrFields) {
        if (this.form.settings.general.qrFields.length) {
          let entryId = data.output;
          this.formEntryId = parseInt(entryId);
          this.isQRView = true;
        }
      } else {
        //window.location.reload();
        this.submissionModal = true;
      }
    },

    closeQRModal() {
      this.submissionModal = true;
      //window.location.reload();
    },

    closeSubmission() {
      this.submissionModal = false;
      window.location.reload();
    },

    async sendInvite(entryId) {
      let origin = location.origin;
      let linkText = "";
      if (
        origin === "https://app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "https://trial.ezofis.com"
      ) {
        linkText = `${origin}/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      } else {
        linkText = `${origin}/app/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      }

      let settingsJson = JSON.parse(this.portal.settingsJson);

      let hasAttachment = false;
      if (
        origin === "https://trial.ezofis.com" &&
        this.$store.state.session.tenantId === 2 &&
        this.portal.id === 52
      ) {
        hasAttachment = true;
      }

      let input = {
        formId: this.formId,
        entryIds: [entryId],
        fieldName: settingsJson.authentication.usernameField,
        inviteUrl: linkText,
        hasAttachment: hasAttachment,
      };
      const { error } = await form.sendInvite(this.portal.id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`Invite Sent`);
    },

    formSaveType(type, itemId) {
      console.log(type, itemId);
      this.savingType = type;
      if (type === "ADD") {
        this.formItemId = 0;
      } else {
        this.formItemId = itemId;
      }
    },

    async getOcrResult(files, control, fieldId, aiSettings) {
      console.log(files, control, fieldId, aiSettings);

      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.id === field);
          console.log(name);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });

        if (files.length) {
          for (let file of files) {
            const formData = new FormData();
            // files.forEach(async (file) => {
            // console.log(file);

            formData.append("repositoryId", this.repositoryId);
            formData.append("file", file);
            if (formFields.length) {
              formData.append("formFields", JSON.stringify(formFields));
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);

                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    if (uploadedPercentage < 50) {
                      this.workflowModel[fieldId][hasFile].uploadedPercentage =
                        uploadedPercentage;
                    } else {
                      this.workflowModel[fieldId][
                        hasFile
                      ].uploadedPercentage = 49;
                    }
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }

              let list = decrypt(
                data,
                this.$store.state.identity.key,
                this.$store.state.identity.iv
              );
              console.log(list);

              let ocrList = JSON.parse(list);
              let ocr = JSON.parse(ocrList);
              let ocrFile = JSON.parse(ocr);

              if (Array.isArray(ocrFile)) {
                ocrFile.forEach((hasFile) => {
                  let hasFieldId = this.formFields.find(
                    (item) => item.label === hasFile.name
                  );
                  if (hasFieldId) {
                    if (hasFile.value) {
                      this.workflowModel[hasFieldId.id] = hasFile.value;
                    }
                  }
                });
                this.ocrResult = ocrFile;
              }
            } catch (error) {
              console.error(error);
              this.$alert.error(`error on get ocr result`);
            }
            // });
          }

          this.uploadFilesFromForm(files, control, fieldId, aiSettings);
        }
      } else {
        this.uploadFilesFromForm(files, control, fieldId, aiSettings);
      }
    },

    async uploadFilesFromForm(files, control, fieldId, aiSettings) {
      console.log(files, control, fieldId, aiSettings);
      this.formUploadFiles = [];

      if (files.length) {
        for (let file of files) {
          const formData = new FormData();
          // files.forEach(async (file) => {
          // console.log(file);
          formData.append("formId", this.formId);
          formData.append("repositoryId", this.repositoryId);
          formData.append("file", file);
          formData.append("filename", file.name);

          if (!isEmpty(aiSettings)) {
            if (aiSettings.formControlValidate) {
              if (aiSettings.formControlValidate.optionsType === "MASTER") {
                formData.append(
                  "masterFormId",
                  aiSettings.formControlValidate.masterFormId
                );
                formData.append(
                  "masterFormColumn",
                  aiSettings.formControlValidate.masterFormColumn
                );
              }
              if (aiSettings.fileDataValidate.length) {
                formData.append(
                  "isValidateFile",
                  aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                );
              }
            }
          }

          let hasFile = this.workflowModel[fieldId].findIndex((item) => {
            return item.name === file.name;
          });
          try {
            const response = await axios({
              url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
              method: "POST",
              data: formData,
              headers: {
                Token: this.$store.state.identity.token,
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: function (progressEvent) {
                let uploadedPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                // console.log(this.workflowModel, fieldId);

                // console.log(hasFile);
                if (hasFile >= 0) {
                  this.$set(
                    this.workflowModel[fieldId][hasFile],
                    "uploadedPercentage",
                    0
                  );
                  if (uploadedPercentage > 50 && uploadedPercentage !== 100) {
                    this.workflowModel[fieldId][hasFile].uploadedPercentage =
                      uploadedPercentage;
                  } else {
                    this.workflowModel[fieldId][
                      hasFile
                    ].uploadedPercentage = 98;
                  }
                }
              }.bind(this),
            });

            const { status, data } = response;
            if (status !== 200) {
              throw "Invalid response";
            }
            file.createdAt = this.$day.newDateTime();
            file.createdBy = this.$store.state.session.email;
            let filesList = JSON.parse(data);
            file.fileId = filesList.fileId;
            file.fileDataValidate = filesList.OCRfieldlist;

            if (hasFile >= 0) {
              this.workflowModel[fieldId][hasFile].uploadedPercentage = 99;
              this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          } catch (error) {
            console.error(error);
            this.$alert.error(`error attaching file`);
          }
          // });
        }

        let _files = Array.from(files);
        // console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // const base64String = await fileToBase64(file);

          this.formUploadFiles.push({
            name: file.name,
            size: file.size,
            // file: base64String,
            fileId: file.fileId,
            createdBy: file.createdBy,
            createdAt: file.createdAt,
            fieldId: fieldId,
            fileDataValidate: file.fileDataValidate,
          });
        }
      }

      if (control) {
        if (control.length) {
          control.forEach((con) => {
            let index = this.readonlyControls.findIndex((item) => {
              return item === con;
            });

            if (index >= 0) {
              this.readonlyControls.splice(index, 1);
            }
          });
        }
      }
    },

    deletedFiles(ids, files) {
      // this.fileControlDeleteFile = [];
      console.log(ids, files);
      this.fileControlDeleteFile.push(ids);

      if (files) {
        if (files.controls) {
          if (files.controls.length) {
            files.controls.forEach((field) => {
              console.log(field);
              this.workflowModel[field] = "";
            });
          }
        }

        this.ocrResult = [];
      }
    },

    async fileControlDeletedFile(file, id) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: id,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // this.fileControlDeleteFile = [];
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    async generateQrCode(fieldId, settings) {
      console.log(settings, "settings");
      this.fileControlFieldSettings = settings;
      this.selectedFieldId = fieldId;
      let name = await this.getLabel(fieldId);
      this.selectedFieldName = name;
      this.showQr = true;
      this.QRFileUploaded = false;
    },

    closeQR(id) {
      this.showQr = false;
      this.QRexpired(id);
    },

    checkMobileUpload(id) {
      console.log(id);
      this.uploadInterval = setInterval(() => {
        this.watchMobileUpload(id);
      }, 5000);
    },

    async QRexpired(id, timeOut) {
      if (!timeOut) {
        this.showQr = false;
      }
      const { payload } = await repository.getQrFileUploadById(id);
      console.log(payload, "payload1");
      let jsonData = JSON.parse(payload.jsonData);
      if (!payload.status) {
        let parsedJsonData = jsonData[0];
        parsedJsonData = { ...parsedJsonData, expired: true };

        let input = {
          id: id,
          url: payload.url,
          jsonData: JSON.stringify([parsedJsonData]),
          status: 0,
        };
        const { error } = await repository.updateJson(id, input);

        if (error) {
          return;
        }
        clearInterval(this.uploadInterval);
        this.uploadInterval = null;
        if (this.processSheet) {
          this.$alert.info(`QR expired`);
        }
      }
    },

    async watchMobileUpload(id) {
      const { error, payload } = await repository.getQrFileUploadById(id);
      console.log(error, payload, "error, payload");

      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData", "payload.status", payload.status);
      if (payload.status) {
        clearInterval(this.uploadInterval);
        this.uploadInterval = null; //
        let parsedJsonData = jsonData[0];
        this.mobileUploadFileJson = parsedJsonData;

        let updatedData = [];
        let workflowModelValue = [];
        parsedJsonData.attachmentList.forEach((file) => {
          updatedData.push({
            name: file.name,
            size: file.size,
            file: "",
            fileId: file.id,
            id: file.id,
            fileControl: true,
            formAttach: true,
            createdBy: file.createdByEmail,
            createdAt: this.$day.format(file.createdAt),
            workflowId: this.workflow.id,
            processId: this.selectedProcess.processId,
            fieldId: parsedJsonData.fieldId,
            fileDataValidate: file.fileDataValidate,
          });

          workflowModelValue.push({
            name: file.name,
            size: file.size,
            file: "",
            fileId: file.id,
            uploadedPercentage: 100,
            createdBy: file.createdByEmail,
            createdAt: this.$day.format(file.createdAt),
            fileDataValidate: file.fileDataValidate,
          });
        });
        // this.formUploadFiles.push(updatedData);

        console.log(workflowModelValue);

        this.formFields.forEach((field) => {
          if (
            field.type === "FILE_UPLOAD" &&
            this.workflowModel[field.value] &&
            field.value == parsedJsonData.fieldId
          ) {
            this.workflowModel[field.value].push(...workflowModelValue);
            // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
          }
        });

        // this.addFileInAttachment();
        this.showQr = false;
        this.QRFileUploaded = true;

        // console.log(updatedData, "updatedData");
      } else {
        console.log("Waiting for uploaded");
        // console.log(this.processSheet,"processSheet")
        if (!this.processSheet) {
          clearInterval(this.uploadInterval);
          this.uploadInterval = null; //
        }
      }
      // this.repositoryId = jsonData[0].repositoryId;
      // this.url =  jsonData[0].url;
    },
  },
};
</script>

<style lang="scss" scoped>
#form-view {
  margin: auto;
  padding: 16px;

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}

#modal .imgCenter {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
